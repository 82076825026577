import { getAuthHeader } from './auth'; // Import your existing auth function
import config from '../config';

/**
 * API request function to support various options.
 *
 * @param {string} endpoint_base - api domain and prefix from the config
 * @param {string} endpoint - The API endpoint to call.
 * @param {object} options - The options for the fetch call, including method, body, and requireAuth.
 */
export async function apiRequest(endpoint_base, endpoint, { method = 'GET', body = null, requireAuth = true, headers = {} } = {}) {
  // Initialize headers with Content-Type
  let finalHeaders = {
    "Content-Type": "application/json",
    ...headers,
  };

  // If authentication is required, attempt to get the user token
  if (requireAuth) {
    try {
      finalHeaders["Authorization"] = await getAuthHeader();
    } catch (error) {
      console.error("Error fetching user token:", error);
      throw error; // Handle token error appropriately
    }
  }

  const fetchOptions = {
    method: method,
    mode: "cors",
    headers: finalHeaders,
  };

  if (body) {
    fetchOptions.body = JSON.stringify(body);
  }

  const response = await fetch(`${endpoint_base}${endpoint}`, fetchOptions);

  if (!response.ok) {
    throw new Error(`Error calling ${endpoint_base}${endpoint}: received ${response.status} ${response.statusText}`);
  }

  return response.json();
}

/**
 * Database API request
 * calls the apiRequest at the registered database endpoint
 */
export async function apiDbRequest(endpoint, { method = 'GET', body = null, requireAuth = true, headers = {} } = {}) {
  console.log("db: ", config.endpoints.database);
  return apiRequest(config.endpoints.database, endpoint, { method, body, requireAuth, headers });
}

/**
 * Embed Text API request
 * calls the apiRequest at the registered text embedding endpoint
 */
export async function apiEmbedTextRequest(endpoint, { method = 'GET', body = null, requireAuth = true, headers = {} } = {}) {
  console.log("embed: ", config.endpoints.embed.text);
  return apiRequest(config.endpoints.embed.text, endpoint, { method, body, requireAuth, headers });
}

/**
 * User API request
 * calls the user management endpoints
 */
export async function apiUserRequest(endpoint, { method = "GET", body = null, requireAuth = true, headers = {} } = {}) {
  console.log("user: ", config.endpoints.user);
  return apiRequest(config.endpoints.user, endpoint, { method, body, requireAuth, headers });
}


/**
 * Instruct API request
 * calls the user management endpoints
 */
export async function apiInstructRequest(endpoint, { method = "GET", body = null, requireAuth = true, headers = {} } = {}) {
  console.log("instruct: ", config.endpoints.instruct);
  return apiRequest(config.endpoints.instruct, endpoint, { method, body, requireAuth, headers });
}


/**
 * Usage API request
 * calls the usage management endpoints
 */
export async function apiUsageRequest(apiRootName, endpoint, { method = "GET", body = null, requireAuth = true, headers = {} } = {}) {
  console.log("usage: ", config.endpoints.usage);
  console.log("usage: ", apiRootName);
  console.log("usage: ", config.endpoints.usage[apiRootName]);
  return apiRequest(config.endpoints.usage[apiRootName], endpoint, { method, body, requireAuth, headers });
}


export async function apiTTSRequest(endpoint, { method = "GET", body = null, requireAuth = true, headers = {} } = {}) {
  console.log("tts: ", config.endpoints.tts);
  return apiRequest(config.endpoints.tts, endpoint, { method, body, requireAuth, headers});
}


/**
 * get the models list from the models endpoint
 */
export async function apiModelsList(endpoint, { method = "GET", body = null, requireAuth = true, headers = {} } = {}){
  console.log("models: ", config.endpoints.models);
  return apiRequest(config.endpoints.models, endpoint, { method, body, requireAuth, headers});
}
