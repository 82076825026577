import React, { useState } from 'react';
import { Button, Comment, Icon, Message as SemanticMessage, Progress, Form, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const getProgressPercent = (status) => {
  switch (status?.toLowerCase()) {
    case 'started':
      return 20;
    case 'pending':
      return 60;
    case 'complete':
      return 100;
    default:
      return 0;
  }
};

const UsageMetadata = ({ usage }) => (
  <Comment.Metadata>
    <div>
      <strong>Usage:</strong> {usage.input_tokens || 0}/{usage.output_tokens || 0} tokens in{' '}
      {usage.duration ? usage.duration.toFixed(2) : '0.00'}s
    </div>
  </Comment.Metadata>
);

UsageMetadata.propTypes = {
  usage: PropTypes.object.isRequired,
};

const ChatMessage = ({
  message,
  editedContent,
  onEdit,
  onSave,
  onCancel,
  onChange,
  onResend,
  onDelete,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const renderContent = () => {
    if (isEditing) {
      return (
        <Form>
          <Form.Field>
            <Input
              value={editedContent}
              onChange={(e) => onChange(e.target.value)}
              fluid
            />
          </Form.Field>
          <Button size="small" primary onClick={() => { onSave(); setIsEditing(false); }} style={{ marginRight: '0.5em' }}>
            Save
          </Button>
          <Button size="small" onClick={() => { onCancel(); setIsEditing(false); }}>
            Cancel
          </Button>
        </Form>
      );
    }

    if (message.loading) {
      return (
        <Progress
          percent={getProgressPercent(message.status)}
          indicating
          progress
          size="small"
          style={{ marginTop: '0.5em' }}
        >
          {message.status}
        </Progress>
      );
    }

    if (message.error) {
      return <SemanticMessage error content={message.error} />;
    }

    return <Comment.Text>{message.content}</Comment.Text>;
  };

  const renderActions = () => {
    const actions = [];

    if (message.role === 'system') {
      actions.push(
        <Button key="edit" icon onClick={() => { setIsEditing(!isEditing); onEdit(); }} circular compact>
          <Icon name="edit" />
        </Button>
      );
    }

    if (onResend && (message.role === 'user' || message.role === 'assistant')) {
      actions.push(
        <Button key="resend" icon onClick={() => onResend(message)} circular compact>
          <Icon name="refresh" />
        </Button>
      );
    }

    if (onDelete) {
      actions.push(
        <Button key="delete" icon onClick={() => onDelete(message)} circular compact>
          <Icon name="trash" />
        </Button>
      );
    }

    return <div style={{ display: 'flex', gap: '0.5em' }}>{actions}</div>;
  };

  return (
    <Comment>
      <Comment.Content>
        {/* Author */}
        <Comment.Author>
          <Icon
            name={message.role === 'user' ? 'user' : message.role === 'assistant' ? 'user' : 'info circle'}
            color={message.role === 'user' ? 'blue' : message.role === 'assistant' ? 'yellow' : 'gray'}
          />
          {message.username}
        </Comment.Author>

        {/* Content */}
        {renderContent()}

        {/* Metadata */}
        <Comment.Metadata style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{new Date(message.created).toLocaleString()}</span>
          {renderActions()}
        </Comment.Metadata>

        {/* Usage Metadata */}
        {message.role === 'assistant' && message.usage && (
          <UsageMetadata usage={message.usage} />
        )}
      </Comment.Content>
    </Comment>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['system', 'user', 'assistant']).isRequired,
    content: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    username: PropTypes.string,
    usage: PropTypes.shape({
      duration: PropTypes.number,
      input_tokens: PropTypes.number,
      output_tokens: PropTypes.number,
    }),
    status: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onResend: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ChatMessage;
