import { useNavigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { signIn } from 'aws-amplify/auth';
import { formFields, components } from './Auth';

export default function LoginComponent() {
  const navigate = useNavigate();

  return (
    <Authenticator
      formFields={formFields}
      components={components}
      services={{
        async handleSignIn({ username, password }) {
          await signIn({ username, password });
          navigate("/", { replace: true }); // Redirect to home
        },
      }}
    />
  );
}
