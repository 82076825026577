import { Theme, Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Button, Form, Segment, Message, Header, Container, Tab } from 'semantic-ui-react';

export const theme: Theme = {
  name: 'SemanticUITheme',
  tokens: {
    components: {
      authenticator: {
        router: {
          boxShadow: 'none',
          borderWidth: '0',
          padding: '1rem',
        },
        form: {
          padding: '1rem',
        },
      },
      button: {
        primary: {
          backgroundColor: '#2185d0', // Semantic UI Primary Blue
          color: 'white',
          borderRadius: '5px',
          padding: '10px 15px',
          fontSize: '1rem',
          _hover: {
            backgroundColor: '#1A73E8', // Slightly darker blue on hover
          },
          _active: {
            backgroundColor: '#1668C2', // Even darker blue when pressed
          },
        },
      },
      fieldcontrol: {
        _focus: {
          boxShadow: '0 0 0 2px #2185d0',
        },
      },
      tabs: {
        item: {
          color: '#2185d0',
          _hover: {
            color: '#1A73E8',
          },
          _active: {
            borderColor: '#2185d0',
            color: '#1668C2',
          },
        },
      },
    },
  },
};


export const components = {
  SignIn: {
    Header() {
      return <Header as="h2" textAlign="center">Sign in</Header>;
    },
    FormFields() {
      const { fields, handleBlur, handleChange } = useAuthenticator();
      return (
        <Form>
          {fields.map((field) => (
            <Form.Input
              key={field.name}
              label={field.label}
              name={field.name}
              type={field.type}
              value={field.value}
              onChange={handleChange}
              onBlur={handleBlur}
              required={field.required}
            />
          ))}
        </Form>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();
      return (
        <Container textAlign="center">
          <Button basic onClick={toForgotPassword} size="small">
            Forgot Password?
          </Button>
        </Container>
      );
    },
  },

  SignUp: {
    Header() {
      return <Header as="h2" textAlign="center">Create a new account</Header>;
    },
    FormFields() {
      const { fields, handleBlur, handleChange } = useAuthenticator();
      return (
        <Form>
          {fields.map((field) => (
            <Form.Input
              key={field.name}
              label={field.label}
              name={field.name}
              type={field.type}
              value={field.value}
              onChange={handleChange}
              onBlur={handleBlur}
              required={field.required}
            />
          ))}
        </Form>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
        <Container textAlign="center">
          <Button basic onClick={toSignIn} size="small">
            Back to Sign In
          </Button>
        </Container>
      );
    },
  },
};

export const formFields = {
  signUp: {
    username: {
      label: 'Username',
      placeholder: 'Enter your username',
      isRequired: true,
    },
    email: {
      label: 'Email Address', // Custom label
      placeholder: 'Enter your email address', // Custom placeholder
      isRequired: true, // Make the email field required
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
      isRequired: true,
    },
  },
  signIn: {
    username: {
      label: 'Email',
      placeholder: 'Enter your email address',
      isRequired: true,
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your password',
      isRequired: true,
    },
  }
};
