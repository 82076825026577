import React from 'react';


const WorkflowHistory = () => {
  return (
    <>
      <h2>History</h2>
      <p>Invocation history of this workflow</p>
    </>
  );
};

export default WorkflowHistory;
