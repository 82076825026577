//import DEFAULT_MODELS from './config/model';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const MDL_API_ENDPOINT = process.env.REACT_APP_MDL_API_ENDPOINT;

export const DEFAULT_CONFIG = {
  endpoints: {
    database: `${API_ENDPOINT}/db`,
    user: `${API_ENDPOINT}/user`,
    models: `${MDL_API_ENDPOINT}`,
    // the remaining are types of models
    usage: {
       databases: `${API_ENDPOINT}/user/usage`,
       models: `${MDL_API_ENDPOINT}/usage`,
    },
    embed: {
      text: `${MDL_API_ENDPOINT}/embed/text`
    },
    instruct: `${MDL_API_ENDPOINT}/instruct`,
    tts: `${MDL_API_ENDPOINT}/tts`,
  },
  models: {}, // DEFAULT_MODELS, populated by calling $(endpoint.models.)/models.json
  version: process.env.REACT_APP_GIT_TAG || "unknown"
};

export default DEFAULT_CONFIG;
