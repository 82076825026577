import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Label } from 'semantic-ui-react';

import { fetchWorkflows, fetchWorkflowInfo } from '../services/workflow';

const AuthenticatedWorkflows = ({ config }) => {
  const [workflows, setWorkflows] = useState([]);

  useEffect(() => {
    const loadWorkflows = async () => {
      try {
        const workflows = await fetchWorkflows(config);
        setWorkflows(workflows);

        // FIXME: Fetch invocation counts for each workflow
/*
        const counts = await Promise.all(
          workflows.map(async (workflow) => {
            const info = await fetchCollectionInfo(workflow);
            return { [workflow]: info };
          })
        );

        // Convert counts array to an object for easy access
        const countsObj = counts.reduce((acc, curr) => ({ ...acc, ...curr }), {});
        setWorkflowCounts(countsObj);
*/
      } catch (error) {
        console.error('Failed to fetch workflows:', error);
      }
    };

    loadWorkflows();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <>
      {workflows.map((workflowName) => (
        <Menu.Item
          key={workflowName}
          as={Link}
          to={`/workflows/${workflowName}`}
          name={workflowName}
        >
          {workflowName}
        </Menu.Item>
      ))}

      <Menu.Item
        as={Link}
        to="/workflows/create"
        name="New..."
      />
    </>
  );
};

export default AuthenticatedWorkflows;
