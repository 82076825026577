import React, { useState } from 'react';
import { Form, Button, Accordion, Icon, Header, Message } from 'semantic-ui-react';

const ConfigEditor = ({ config, onSave, nonEditableKeys = [] }) => {
  // Local copy of config for editing
  const [editableConfig, setEditableConfig] = useState({ ...config });
  const [activeIndex, setActiveIndex] = useState([]);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) =>
      prevIndex.includes(index)
        ? prevIndex.filter((i) => i !== index)
        : [...prevIndex, index]
    );
  };

  const handleInputChange = (path, value) => {
    setEditableConfig((prevConfig) => {
      const newConfig = { ...prevConfig };
      let target = newConfig;
      const keys = path.split('.');

      keys.slice(0, -1).forEach((key) => {
        if (!target[key]) {
          target[key] = {}; // Ensure the nested object exists
        }
        target = target[key] = { ...target[key] }; // Copy each level
      });

      target[keys[keys.length - 1]] = value; // Update the final key

      return newConfig; // Return the updated config
    });
  };

  const renderField = (key, value, path) => {
    const fullPath = path ? `${path}.${key}` : key;
    const isEditable = !nonEditableKeys.some((nonEditableKey) =>
      fullPath.startsWith(nonEditableKey)
    );

    if (typeof value === 'object' && value !== null) {
      return (
        <Accordion styled fluid key={fullPath}>
          <Accordion.Title
            active={activeIndex.includes(fullPath)}
            index={fullPath}
            onClick={() => toggleAccordion(fullPath)}
          >
            <Icon name="dropdown" />
            {key}
          </Accordion.Title>
          <Accordion.Content active={activeIndex.includes(fullPath)}>
            {Object.entries(value).map(([nestedKey, nestedValue]) =>
              renderField(nestedKey, nestedValue, fullPath)
            )}
          </Accordion.Content>
        </Accordion>
      );
    } else {
      return (
        <Form.Field key={fullPath} disabled={!isEditable}>
          <label>{fullPath}</label>
          <input
            type="text"
            value={value || ''}
            onChange={(e) =>
              isEditable && handleInputChange(fullPath, e.target.value)
            }
            readOnly={!isEditable}
          />
          {!isEditable && (
            <Message size="mini" color="grey">
              This field is managed by the API and cannot be edited.
            </Message>
          )}
        </Form.Field>
      );
    }
  };

  const handleSave = () => {
    onSave(editableConfig); // Send updated config to the parent
  };

  return (
    <div>
      <Header as="h2">Edit Configuration</Header>
      <Form>
        {Object.entries(editableConfig).map(([key, value]) =>
          renderField(key, value, '')
        )}
        <Button type="button" onClick={handleSave} primary>
          Save
        </Button>
      </Form>
    </div>
  );
};

export default ConfigEditor;
