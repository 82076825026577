import React, { useState, useEffect } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { pollForResult } from '../../services/tts'; // Import the polling function

const TTSResponse = ({ messageText, messageId }) => {
  const [response, setResponse] = useState(null); // The backend response
  const [loading, setLoading] = useState(true); // Loading state for this component
  const [error, setError] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);

  useEffect(() => {
    let isMounted = true; // To prevent state updates if component is unmounted

    const fetchResponse = async () => {
      try {
        const result = await pollForResult(messageId); // Polling function

        if (isMounted) {
          setResponse(result);
          setLoading(false);

          // Convert base64 to a blob and create an object URL
          if (result.status === "complete" && result.data) {
            const binaryString = atob(result.data);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], { type: result.mimetype || "audio/wav" });
            setAudioUrl(URL.createObjectURL(blob));
          }
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchResponse();

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [messageId]);

  if (loading) {
    return <Loader active inline size="small" />; // Show loader while waiting
  }

  if (error) {
    return <Message error>{error}</Message>; // Show error message if there's an error
  }

  if (!response) {
    return null; // Or you could display a placeholder
  }

  return (
    <>
      <p>{messageText}</p>
      {audioUrl && (
        <audio controls>
          <source src={audioUrl} type={response.mimetype || "audio/wav"} />
          Your browser does not support the audio element.
        </audio>
      )}
      <p>
        <strong>Status:</strong> {response.status}
      </p>
    </>
  );
};

export default TTSResponse;
