import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { Form, Button, Message, Container, Header } from 'semantic-ui-react';

export default function ConfirmSignup() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  async function handleConfirm() {
    try {
      await confirmSignUp({ email, confirmationCode: code });
      setSuccess('Successfully confirmed! Redirecting to login...');
      setTimeout(() => navigate('/user/login'), 2000);
    } catch (err) {
      setError(err.message);
    }
  }

  async function handleResendCode() {
    try {
      await resendSignUpCode({ email });
      setSuccess('Confirmation code resent! Check your email.');
    } catch (err) {
      setError(err.message);
    }
  }

  return (
    <Container style={{ maxWidth: '400px', marginTop: '5vh' }}>
      <Header as="h2" textAlign="center">Enter Verification Code</Header>
      <Form>
        <Form.Input
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <Form.Input
          label="Verification Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter the code you received"
        />
        {error && <Message negative>{error}</Message>}
        {success && <Message positive>{success}</Message>}
        <Button primary fluid onClick={handleConfirm}>Confirm Sign Up</Button>
        <Button basic fluid onClick={handleResendCode} style={{ marginTop: '10px' }}>
          Resend Code
        </Button>
      </Form>
    </Container>
  );
}
