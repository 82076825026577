import { apiDbRequest } from './api';

export const fetchWorkflows = async () => {
  return await apiDbRequest('', { method: 'GET', requireAuth: true });
};

export const createWorkflow = async (workflowName, embeddingSize) => {
  const body = {
    name: workflowName,
    embedding_vector_length: embeddingSize,
  };
  return await apiDbRequest(`/${workflowName}`, { method: 'POST', body });
};

export const fetchWorkflowInfo = async (workflowName) => {
  return await apiDbRequest(`/${workflowName}`, { method: 'GET' });
};

export const deleteWorkflow = async (workflowName) => {
  return await apiDbRequest(`/${workflowName}`, { method: 'DELETE' });
};
