import React from 'react';


const Workflows = () => {
  return (
    <>
      <h2>Your Workflows</h2>
      <h2>Public Workflows</h2>
    </>
  );
};

export default Workflows;
