import React from 'react';


const CreateWorkflow = () => {
  return (
    <>
      <h2>Create a Workflow</h2>
    </>
  );
};

export default CreateWorkflow;
