import React from 'react';
import { Button, Header } from 'semantic-ui-react';
import config from '../../config';
import ApiKeyManager from './ApiKeyManager';
import UsageStats from './UsageStats';
import ConfigEditor from './ConfigEditor';

const UserAccount = ({ handleLogout, config, onConfigSave, nonEditableKeys = [] }) => {
  return (
    <>
      <Button as="Link" name="logout" onClick={handleLogout}>
        Logout
      </Button>

      <ApiKeyManager />

      <Header as="h2">Usage Statistics</Header>

      {Object.entries(config.endpoints.usage).map(([key, endpoint]) => (
        <div key={key}>
          <Header as="h3">{key}</Header>
          <UsageStats apiRootName={key} />
        </div>
      ))}

      <ConfigEditor config={config} onSave={onConfigSave} nonEditableKeys={nonEditableKeys} />
    </>
  );
};

export default UserAccount;
