import { apiTTSRequest } from './api';
import { DEFAULT_CONFIG } from '../config';

export const textToSpeech = async (text, langcode = "en-GB") => {
  const config = DEFAULT_CONFIG;

  console.log("textToSpeech: ", text, langcode);

  const modelName = "mms-tts-eng"; // FIXME: use the langcode to get this modelname

  const body = {
    model: config.models.tts[modelName].api_name,
    input: text,
    language_code: langcode
  };

  try {
    const response = await apiTTSRequest('/', {
      method: 'POST',
      requireAuth: true,
      body,
    });

    console.log("response: ", response);

    const messageId = response.message_id;
    return messageId;
  } catch (error) {
    throw new Error(`Error in TTS request: ${error.message}`);
  }
};

export const pollForResult = async (messageId) => {
  const intervalTime = 2000; // Poll every 2 seconds

  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      try {
        const response = await apiTTSRequest(`/${messageId}`, { method: 'GET' });

        if (response.status === "complete") {
          clearInterval(interval);
          resolve(response); // Resolve the promise with the final response
        } else if (response.status === "error") {
          console.error("response.status=error", response);
          clearInterval(interval);
          resolve(response);
        }
      } catch (error) {
        clearInterval(interval);
        reject(new Error(`Error polling for TTS result: ${error.message}`));
      }
    }, intervalTime);
  });
};
