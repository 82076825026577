import React, { useState } from 'react';
import { Form, Button, List, Segment, Message } from 'semantic-ui-react';
import { textToSpeech } from '../../services/tts';
import TTSResponse from './TTSResponse';

const TTSInteraction = () => {
  const [message, setMessage] = useState('');
  const [submissions, setSubmissions] = useState([]); // Store submissions with message and messageId
  const [loading, setLoading] = useState(false); // Loading state for the submit button
  const [error, setError] = useState(null);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Submit the message and get the messageId
      const messageId = await textToSpeech(message);

      // Add the submission to the submissions array
      setSubmissions((prev) => [...prev, { message, messageId }]);

      // Clear the input field
      setMessage('');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSendMessage}>
        <Form.Field>
          <input
            type="text"
            placeholder="Enter text to convert to speech..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </Form.Field>
        <Button type="submit" loading={loading} disabled={!message.trim()}>
          Convert to Speech
        </Button>
        {error && <Message error>{error}</Message>}
      </Form>
      <List>
        {submissions.map((item, index) => (
          <List.Item key={index}>
            <Segment>
              <TTSResponse messageText={item.message} messageId={item.messageId} />
            </Segment>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default TTSInteraction;
