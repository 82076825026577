import React from 'react';


const WorkflowDetails = () => {
  return (
    <>
      <h2>Workflow Details</h2>
    </>
  );
};

export default WorkflowDetails;
