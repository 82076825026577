import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Accordion, Transition } from 'semantic-ui-react';

const ModelList = ({ activeIndex, handleAccordionClick, config }) => {
  return (
    <>
      {Object.keys(config.models).map((modelType, index) => (
          <Menu.Item key={modelType}>
        <Accordion key={modelType}>
          <Accordion.Title
            index={index}
            active={activeIndex === index}
            onClick={() => handleAccordionClick(index)}
          >
            <strong>{modelType}</strong>
          </Accordion.Title>
          <Transition visible={activeIndex === index} animation={activeIndex === index ? 'slide up' : 'slide down'} duration={300}>
          <Accordion.Content active={activeIndex === index}>
            <Menu.Menu>
              {Object.keys(config.models[modelType]).map((modelName) => (
                <Menu.Item
                  key={modelName}
                  as={Link}
                  to={`/models/${modelType}/${modelName}`}
                  name={modelName}
                >
                  {modelName}
                </Menu.Item>
              ))}
            </Menu.Menu>
          </Accordion.Content>
          </Transition>
        </Accordion>
          </Menu.Item>
      ))}
    </>
  );
};

export default ModelList;
