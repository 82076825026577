import { useNavigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { signUp } from 'aws-amplify/auth';
import { formFields, components } from './Auth';

export default function SignUpComponent() {
  const navigate = useNavigate();

  return (
    <Authenticator
      formFields={formFields}
      initialState="signUp"
      components={components}
      services={{
        async handleSignUp({ email, password }) {
          await signUp({
            username: email,
            email: email,
            password,
            options: {
              userAttributes: { email },
            },
          });

          navigate('/user/confirm-signup', { replace: true });
        }
      }}
    />
  );
}
