import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Container, Grid } from 'semantic-ui-react';

import './config/amplifyConfig';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { theme as customTheme } from './components/users/Auth';
import LoginComponent from './components/users/Login';
import SignUpComponent from './components/users/SignUp';
import ConfirmSignup from './components/users/ConfirmSignup';


import '@aws-amplify/ui-react/styles.css';

import { DEFAULT_CONFIG } from './config';
import { apiModelsList } from './services/api';

import Sidebar from './components/Sidebar';
import LandingPage from './LandingPage';
import UserAccount from './components/users/Account';

import Collections from './components/Collections';
import CreateCollection from './components/CreateCollection';
import CollectionDetails from './components/CollectionDetails';

import { Models, ModelOverview } from './components/models';
import { Workflows, CreateWorkflow, WorkflowHistory, WorkflowDetails } from './components/workflows';


const ProtectedRoute = ({ children }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  if (authStatus === 'configuring') {
    return <div>Loading...</div>; // Render a loader while Authenticator is setting up
  }

  return authStatus === 'authenticated' ? children : <Navigate to="/user/login" replace />;
};

const App = () => {
  const [config, setConfig] = useState(DEFAULT_CONFIG);
  const { user, authStatus, signOut } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);
  const [error, setError] = useState(null);

  const transformModelsResponse = (apiResponse) => {
    const transformedData = {};

    Object.entries(apiResponse).forEach(([fullModelName, modelDetails]) => {
      const { type, organization, output, input, parameter_count } = modelDetails;

      // Extract the model short name (remove prefix before '/')
      const modelName = fullModelName.split("/").pop();

      // Ensure the type exists in the transformed object
      if (!transformedData[type]) {
        transformedData[type] = {};
      }

      // Construct the transformed model entry
      transformedData[type][modelName] = {
        api_name: fullModelName,
        //description: "Maps sentences & paragraphs to a vector space for tasks like clustering or semantic search.",
        provider: {
          name: organization,
          links: {
            org: `https://huggingface.co/${organization}`,
            model: `https://huggingface.co/${fullModelName}`,
          },
        },
        inputs: [input],
        outputs: [output]
      };
    });

    return transformedData;
  };


  // Fetch models on mount
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const rawModels = await apiModelsList(`/models.json`);
        const models = await transformModelsResponse(rawModels);

        // update config with new models
        setConfig((prevConfig) => ({
          ...prevConfig,
          models,
        }));
      } catch (err) {
        console.error(err.message);
        setError(err.message);
        config.models = {}
        return;
      }
    };

    fetchModels();
  }, []);

  return (
    <Router>
      <Grid centered>
        <Grid.Row>
          <Grid.Column width={4}>
            <Sidebar
              currentUser={authStatus === 'authenticated' ? user : null}
              //activeItem={activeItem}
              //setActiveItem={setActiveItem}
              //setSelectedCollectionId={setSelectedCollectionId}
              config={config}
            />
          </Grid.Column>

          <Grid.Column floated="left" width={11}>
            <Container>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<LandingPage />} />
                <Route path="/user/signup" element={<SignUpComponent />} />
                <Route path="/user/login" element={<LoginComponent />} />
                <Route path="/user/confirm-signup" element={<ConfirmSignup />} />

                <Route path="/collections" element={<Collections />} />
                <Route path="/models" element={<Models />} />
                <Route path="/workflows" element={<Workflows />} />

                {/* Protected Routes */}
                <Route path="/user/account" element={<ProtectedRoute><UserAccount handleLogout={signOut} config={config} onConfigSave={(newConfig) => setConfig(newConfig)} nonEditableKeys={["models"]} /></ProtectedRoute> }/>

                <Route path="/collections/create" element={<ProtectedRoute><CreateCollection /></ProtectedRoute> } />
                <Route path="/collections/:collectionId" element={<ProtectedRoute><CollectionDetails /></ProtectedRoute> } />
                <Route path="/models/:modelType/:modelName" element={<ProtectedRoute><ModelOverview models={config.models} /></ProtectedRoute> } />

                <Route path="/workflows/create" element={<ProtectedRoute><CreateWorkflow /></ProtectedRoute> } />
                <Route path="/workflows/:workflowName" element={<ProtectedRoute><WorkflowDetails /></ProtectedRoute> } />
                <Route path="/workflows/:workflowName/history" element={<ProtectedRoute><WorkflowHistory /></ProtectedRoute> } />


                {/* API Docs */}
                { /*
                <Route path="/docs/database" element={<ApiDocs specUrl="https://web.dev.db.bayis.co.uk/openapi.json" />} />
                <Route path="/docs/models" element={<ApiDocs specUrl="https://web.dev.db.bayis.co.uk/openapi.json" />} />
                */}

              </Routes>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Router>
  );
};


export default function AppWrapper() {
  return (
    <ThemeProvider theme={customTheme}>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
    </ThemeProvider>
  );
}
